<template>
  <i-container v-if="response" class="_margin-bottom-6 bock--container">
    <i-row>
      <i-column
        xs="12"
        sm="12"
        md="12"
        lg="6"
        xl="6"
        v-for="(exhibit, index) in response.results"
        :key="`exhibit-${index}`"
        class="tile"
      >
        <div
          class="bock--exhibit bock--hover"
          @click="
            $router.push({ name: 'Exhibition', params: { uid: exhibit.uid } })
          "
        >
          <prismic-image
            v-if="exhibit.data.install_shot.wide"
            :field="exhibit.data.install_shot.wide"
            class="image -responsive"
          />
          <div
            class="
              _padding-top-0
              _margin-bottom-1
              _display-flex
              _justify-content-space-between
            "
          >
            <div style="min-width: 182px">
              <small class="_text-muted _font-weight-semibold bock--trunc">{{
                exhibit.data.title
              }}</small>
            </div>
            <div>
              <small
                v-if="exhibit.data.year"
                class="_text-muted _font-weight-semibold bock--trunc"
                >{{ exhibit.data.year }}</small
              >
              <small
                v-if="
                  exhibit.data.gallery
                "
                class="_text-muted _font-weight-semibold bock--trunc"
                >&nbsp; {{ exhibit.data.gallery }},</small
              >
              <small class="_text-muted _font-weight-semibold bock--trunc"
                >&nbsp; {{ exhibit.data.location }}</small
              >
            </div>
          </div>
        </div>
      </i-column>
    </i-row>
    <!-- <pre v-if="response" v-html="JSON.stringify(response, null, 2)"></pre> -->
  </i-container>
</template>

<script>
export default {
  name: "Exhibitions",
  data() {
    return {
      response: null,
    };
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "exhibition"),
        {
          orderings: "[my.exhibition.sort_date desc]",
          pageSize: 100,
        }
      );
      this.response = response;
    },
  },
  created() {
    this.getContent();
  },
};
</script>

<style lang="scss">
.bock--exhibit:hover {
  cursor: pointer;
  ._text-gray-70 {
    color: $color-black !important;
  }
}
</style>
